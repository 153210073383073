import {useRequestHeaders, useRuntimeConfig} from '#imports';

export type Breakpoints = {isMobile: boolean; isTablet: boolean; isWide: boolean};

export const useBreakpoints = () => {
    // allow breakpoints to be undefined at build time for apps that does not use the slices
    const runtimeConfig = useRuntimeConfig().public;
    if (!runtimeConfig.breakpoints) {
        throw new Error('Missing breakpoint configuration for prismic slices');
    }
    const breakpoints = runtimeConfig.breakpoints as unknown as {mobile: number; tablet: number; wide: number};

    if (import.meta.server) {
        const {'user-agent': userAgent} = useRequestHeaders(['user-agent']);
        const isMobileClient = !!userAgent && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        return {
            isWide: false,
            isTablet: isMobileClient,
            isMobile: isMobileClient,
        };
    } else {
        const isMobileWindow = window.innerWidth <= breakpoints.mobile;
        const isTabletWindow = window.innerWidth <= breakpoints.tablet;
        const isWideWindow = window.innerWidth > breakpoints.wide;
        return {
            isWide: isWideWindow,
            isTablet: isTabletWindow,
            isMobile: isMobileWindow,
        };
    }
};
