import type {Ref} from 'vue';
import {onMounted} from 'vue';

export const useComponentTracking = (rootContainer: Ref<HTMLElement | null>) => {
    function addComponentTrackingParam(anchor: HTMLAnchorElement) {
        if (!anchor) {
            return;
        }
        const {href} = anchor;
        const {sourceComponent} = anchor.dataset;
        if (href && sourceComponent) {
            const [baseHref, hash] = href.split('#');
            const separator = href.includes('?') ? '&' : '?';
            const hashParam = href.includes('#') ? `#${hash}` : '';
            const newHref = `${baseHref}${separator}source_component=${sourceComponent}${hashParam}`;
            anchor.setAttribute('href', newHref);
        }
    }

    onMounted(() => {
        if (!rootContainer.value) {
            return;
        }
        const anchors: HTMLAnchorElement[] = Array.from(rootContainer.value.querySelectorAll('a[data-source-component]'));
        anchors.forEach(addComponentTrackingParam);
    });
};
